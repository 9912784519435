import React from 'react';
import { graphql, StaticQuery } from "gatsby";
import Layout from '../components/Layout';
import PostStyle from './posts.module.css'
import TutorialsStyle from './tutorials.module.css'
import PostComponent from '../components/postComponent'

function Tutorial() {
    return (
        <StaticQuery
            query={graphql`
                query TutorialsQuery {
                    posts: allMdx(filter: {frontmatter: {category: {eq: "tutorial"}}, fields: {isBlog: {eq: true}}}, sort: {order: ASC, fields: frontmatter___index}) {
                        edges {
                            node {
                                fields {
                                    slug
                                }
                                excerpt
                                frontmatter {
                                    date
                                    title
                                    subtitle
                                    image {
                                        publicURL
                                    }
                                }
                            }
                        }
                    }
                    site: site {
                        siteMetadata {
                          siteUrl
                        }
                    }
                }
            `}
            render = {({posts, site}) => {
                return(
                    <Layout>
                        <div className={TutorialsStyle.cover} />
                        <div className={PostStyle.background} />
                        <div className={PostStyle.container}>
                            <h1 style={{textAlign: 'center'}}>Tutorials</h1>
                            <div className={PostStyle.postsContainer}>
                                {posts.edges.map(({ node: post }) => 
                                (
                                    <PostComponent 
                                        title={post.frontmatter.title}
                                        subtitle={post.frontmatter.subtitle}
                                        date={post.frontmatter.date}
                                        excerpt={post.excerpt}
                                        img={post.frontmatter.image.publicURL}
                                        key={post.frontmatter.title}
                                        siteUrl={site.siteMetadata.siteUrl}
                                        slug={post.fields.slug}
                                    />
                                ))}
                            </div>
                        </div>
                    </Layout>
                )
            }}
        />
    )
}

export default Tutorial;